<template lang='pug'>
.changeDisplayOrder
  h1 change display order
  .shirt(v-for='shirt in shirts')
    p {{ shirt.displayOrder+1 }} {{ shirt.id }}
      i(class="fas fa-arrow-up" @click='moveUp(shirt.displayOrder)')
      i(class="fas fa-arrow-down" @click='moveDown(shirt.displayOrder)')

</template>


<script>
import { db } from '../../funky/firebaseInit'

export default {
  methods: {
    moveUp(order) {
      if (order == 0) {
        // first one can't go up
        return
      }
      const uppedShirt = this.shirts[order]
      const downedShirt = this.shirts[order-1]

      this.moveItem(order,order-1)
      uppedShirt.displayOrder--
      downedShirt.displayOrder++

      // directly change fb
      db.collection('shirts')
        .doc(downedShirt.id)
        .update({displayOrder: order}),

      db.collection('shirts')
        .doc(uppedShirt.id)
        .update({displayOrder: order-1})
    },

    moveDown(order) {
      if (order > this.shirts.length-2) {
        // last one can't go down
        return
      }
      const downedShirt = this.shirts[order]
      const uppedShirt = this.shirts[order+1]

      this.moveItem(order,order+1)
      downedShirt.displayOrder++
      uppedShirt.displayOrder--

      // directly change fb
      db.collection('shirts')
        .doc(downedShirt.id)
        .update({displayOrder: order+1})

      db.collection('shirts')
        .doc(uppedShirt.id)
        .update({displayOrder: order})
    },

    moveItem(from, to) {
      // remove `from` item and store it
      var f = this.shirts.splice(from, 1)[0];
      // insert stored item into position `to`
      this.shirts.splice(to, 0, f);
    },

  }, // end methods

  computed: {
    shirts() {
      return this.$store.state.shirts
    }
  },

}
</script>

<style lang='stylus' scoped>
i
  margin-left 1em
  &:hover
    cursor pointer
    color red
</style>