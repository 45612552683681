import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    shirts: [],
    // purchases: [],
    cart: []
  },
  getters: {
  },

  // we commit mutations
  // shouldn't put async code here!
  // shouldn't call these directly - whay not?
  // these get called from the actions below
  mutations: {
    addShirt: (state, shirt) => {
      // only if doesn't contain shirt already?
      if (!state.shirts.includes(shirt)) {
        state.shirts.push(shirt)
      } else {
        console.log('shirt already there!')
      }
      state.shirts = [ ...new Set(state.shirts)]
    },
    changeShirtName: (state, shirt) => {
      state.shirts[shirt.id].name = shirt.name
    },
    addDesignBlob: (state, shirt) => {
      state.shirts[shirt.id].designBlob = shirt.url
    },
    addShirtBlob: (state, shirt) => {
      state.shirts[shirt.id].shirtBlob = shirt.url
    },
    changeShirtPrice: (state, shirt) => {
      state.shirts[shirt.id].price = shirt.price
    },
    changeTypeColour: (state, payload) => {
      state.shirts[payload.shirtIdx].types[payload.typeIdx].colour = payload.colour
    },
    changeTypeSize: (state, payload) => {
      state.shirts[payload.shirtIdx].types[payload.typeIdx].size = payload.size
    },
    changeTypeStock: (state, payload) => {
      state.shirts[payload.shirtIdx].types[payload.typeIdx].stock = payload.stock
    },
    addNewType: (state, payload) => {
      // const newType = {
      //   colour: type.colour,
      //   size: type.size,
      //   stock: type.stock
      // }
      console.log('store ', payload.newType)
      state.shirts[payload.shirtIdx].types.push(payload.newType)
    },
    deleteShirt: (state, shirt) => {
      const index = state.shirts.findIndex(s => s.name == shirt)
      state.shirts.splice(index, 1)
    },
    deleteType: (state, payload) => {
      // const {shirtIdx, typeIdx} = type
      state.shirts[payload.shirtIdx].types.splice(payload.typeIdx, 1)
    },
    clearStore: (state) => {
      state.shirts = []
    },
    removeShirtFromStock: (state, indices) => {
      // decrement stock from shirt type
      state.shirts[indices.shirtIndex].types[indices.typeIndex].stock--
    },
    addShirtToStock: (state, indices) => {
      // decrement stock from shirt type
      state.shirts[indices.shirtIndex].types[indices.typeIndex].stock++
    },


    // purchases
    addToCart(state, purch) {
      // console.log('storeAddPurch.purch', purch)
      var match = false
      let ps = state.cart
      let matchingIndex = null

      // has this type already been purchased?
      for (var i=0; i<ps.length; i++) {
        if (ps[i].shirtIndex == purch.shirtIndex
            && ps[i].typeIndex == purch.typeIndex) {
          match = true
          matchingIndex = i
        }
      }

      if ((match === false) || (ps.length == 0)) {
        // new purchase of that type
        const newP = {
          'shirtIndex': purch.shirtIndex,
          'typeIndex': purch.typeIndex,
          'quantity': 1
        }
        ps.push(newP)
        state.shirts[purch.shirtIndex].types[purch.typeIndex].stock--
      } else {
        // already have shirt of that type in cart
        state.cart[matchingIndex].quantity++
        state.shirts[purch.shirtIndex].types[purch.typeIndex].stock--
      }

      // qq update firebase here, or after paypal purchase?
    }, // end addToCart

    removePurchase: (state, removedPurchaseIndex) => {
      state.cart.splice(removedPurchaseIndex, 1)
    },

    addOneShirtOfSameType: (state, indices) => {
      // console.log('store$', indices)
      // find matching purchase, then increment quantity
      state.cart[indices.purchaseIndex].quantity++
    },

    removeOneShirtOfSameType: (state, indices) => {
      // console.log('store', purchaseIndex)
      state.cart[indices.purchaseIndex].quantity--
     },

    removeShirtTypeFromCart: () => {},

    clearCart: (state) => {
      state.cart = []
    },
  },

  // dispatch these - call them directly from the components!
  // for async stuff (context is sorta like the store)
  // always better than mutating directly, not just for async
  // good for vue tools and debugging!
  // payload is any parameter passed in
  actions: {
    addShirtBlob: (context, payload) => {
      context.commit('addShirtBlob', payload)
    },
    changeShirtName: (context, payload) => {
      context.commit('changeShirtName', payload)
    },
    addDesignBlob: (context, payload) => {
      context.commit('addDesignBlob', payload)
    },
    changeShirtPrice: (context, payload) => {
      context.commit('changeShirtPrice', payload)
    },
    changeTypeColour: (context, payload) => {
      context.commit('changeTypeColour', payload)
    },
    changeTypeSize: (context, payload) => {
      context.commit('changeTypeSize', payload)
    },
    changeTypeStock: (context, payload) => {
      context.commit('changeTypeStock', payload)
    },
    addNewType: (context, payload) => {
      context.commit('addNewType', payload)
    },
    deleteType: (context, payload) => {
      context.commit('deleteType', payload)
    },
    addNewShirt: (context, payload) => {
      context.commit('addShirt', payload)
    },
    deleteShirt: (context, payload) => {
      context.commit('deleteShirt', payload)
    },
    removeShirtFromStock: (context, indices) => {
      context.commit('removeShirtFromStock', indices)
    },

    // purchases
    addToCart: (context, purchasedIndices) => {
      context.commit('addToCart', purchasedIndices)
    },
    clearCart: (context) => {
      context.commit('clearCart')
    },
    // addOneShirtOfSameType: (context, indices) => {
    //   // console.log('mut', indices)
    //   context.commit('addOneShirtOfSameType', indices)
    // },
    // removeOneShirtOfSameType: (context, purchaseIndex) => {
    //   context.commit('removeOneShirtOfSameType', purchaseIndex)
    // },

  },
})
