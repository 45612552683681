import Vue from 'vue'
import VueRouter from 'vue-router'
import Admin from '../components/admin/Admin.vue'
import Home from '../views/Home.vue'
import Cart from '../components/Cart.vue'
import Contact from '../components/Contact.vue'
import ViewShirts from '../components/ViewShirts.vue'

import Terms from '../components/footer/Terms.vue'
import Returns from '../components/footer/Returns.vue'
import Privacy from '../components/footer/Privacy.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/viewshirts',
    name: 'viewshirts',
    component: ViewShirts
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms
  },
  {
    path: '/returns',
    name: 'returns',
    component: Returns
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
