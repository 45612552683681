import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

// analytics
// import { initializeApp } from "firebase/compat/app";
// import { getAnalytics } from "firebase/compat/analytics";
// import "firebase/compat/analytics";
// import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyAWIoc6dSTrQ_1OpxcpvXaS23nLCNu6WnM",
  authDomain: "noshit-shirts.firebaseapp.com",
  projectId: "noshit-shirts",
  storageBucket: "noshit-shirts.appspot.com",
  messagingSenderId: "900249137078",
  appId: "1:900249137078:web:bd6f9556ea2081767a9b17",
  // measurementId: "G-9MQM86WLH7"
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
// const analytics = getAnalytics(app);
// const analytics = getAnalytics();
// const analytics = firebase.analytics();
// firebase.analytics().logEvent('app started');
// firebase.analytics()

// Vue.config.productionTip = false

// Vue.prototype.$analytics = firebase.analytics()

const db = firebase.firestore()
const fv = firebase.firestore.FieldValue

// export { firebaseApp.firestore(), analytics }
// export default firebaseApp.firestore()
export { db, fv }