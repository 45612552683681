<template lang='pug'>
.admin
  .notauthed(v-if='!authed')
    h1 Login
    form(@submit.prevent='authorize')
      input(type='text' v-model='email' required placeholder='yer email address')
      input(type='password' v-model='password' required placeholder='secret code')
      button(type='submit') authorize me

      //- p
      //- button(@click='testSales') angelo testing
      //- p {{ testSale }}
      //- p {{ ppTime }} :: {{ realTime }} - {{ realDate}}
      //- p {{ ppPost }}
      //- p {{ realPost }}

  .authed(v-else)
    h1 admin corner - logged in
    h3 shirt stuff
    button(@click='addNewDesign') add new shirt design
    button(@click='editDesign') edit existing design or change stock
    button(@click='changeOrders') change shirt display order
    button.del(@click='deleteDesign') delete existing design
    p
    hr

    //- h3 sales stuff -todo
    //-   button(@click='showSales') show sales

    .adding(v-if="action=='adding'")
      AddNewDesign

    .editing(v-if="action=='editing'")
      EditDesign

    .ordering(v-if="action=='ordering'")
      ChangeDisplayOrder

    .deleteDesign(v-if="action=='deleting'")
      h1 deleting
      h2 select shirt to delete

      select(v-model='shirtName2Delete')
        option(value='select design to delete' disabled selected) select shirt to delete
        option(v-for='shirt in shirts' :value='shirt.id') {{ shirt.id }}
      button.del(@click='deleteShirt') delete shirt

    //- .sales(v-if='salesShown')
    //-   // load sales
    //-   p todo - insert list of sales here

</template>


<script>
import firebase from 'firebase/compat'
import { db } from '../../funky/firebaseInit'
import AddNewDesign from './AddNewDesign.vue'
import EditDesign from './EditDesign.vue'
import ChangeDisplayOrder from './ChangeDisplayOrder.vue'
// import blobbify from '../../mixins/blobbify'

export default {
  name: 'Admin',
  // mixins: [ blobbify ],
  components: { AddNewDesign, EditDesign, ChangeDisplayOrder },
  data() {
    return {
      email: null,
      password: null,
      authed: false,  // todo uncomment!!!
      // authed: true,
      action: '',
      shirtName2Delete: 'select shirt to delete',

      // salesShown: false,

      // testSale: {},
      // ppTime: '',
      // realTime: '',
      // realDate: '',
      // ppPost: {},
      // realPost: ''
    }
  },

  methods: {
    authorize(e) {
      firebase.auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          user => {
            // alert('okily dokily, I trust you')
            this.authed = true
          },
          err => {
            alert(err.message)
            alert('fuck off, you dirty hacker!')
        })
    },

    addNewDesign() {
      this.action = 'adding'
    },

    editDesign() {
      this.action = 'editing'
    },

    deleteDesign() {
      this.action = 'deleting'
    },

    changeOrders() {
      this.action = 'ordering'
    },

    deleteShirt() {
      let message = "are you sure you want to delete the '"
      message += this.shirtName2Delete + "' design?"
      message += "\n\nit's a pretty radical decision..."
      if (confirm(message)) {
        if (confirm('really really sure??')) {
          this.$store.dispatch('deleteShirt', this.shirtName2Delete )
          db.collection('shirts').doc(this.shirtName2Delete).delete()
          alert("...and it's gone")
        } else {
          alert('phew, that was close')
        }
      }
      this.shirtName2Delete = null
      this.deleting = false
    },

    // showSales() {
    //   //qq todo
    // },

    // testSales() {
    //   const testSale =
    //     {
    //       payPalOrderId: "0S673678L3572014K",
    //       time: "2021-12-11T16:27:41Z",
    //       punter: "John Doe",
    //       email: "sb-ieoxc618155@business.example.com",
    //       postal: {
    //         address_line_1: "WOLVERHAMPTON QUEENS SQ",
    //         admin_area_2: "LEICESTER",
    //         admin_area_1: "LEICESTERSHIRE",
    //         postal_code: "LE87 2BB",
    //         country_code: "GB"
    //       },
    //       order: [
    //         {
    //           shirt: "dig for brexit",
    //           size: "M",
    //           quantity: 1
    //         }
    //       ]
    //     }

    //   const timeArray = testSale.time.split('T')
    //   console.log(timeArray[0], ' - ', timeArray[1])


    // }, // end test


  }, // end methods

  computed: {
    shirts() {
      return this.$store.state.shirts
    },
  },

}
</script>

<style lang="stylus" scoped>
.admin
  margin 1em 2em

input
  display inline-block
  background var(--primary)
  padding-left 1em
  color var(--light)

input[type=file]::file-selector-button
  transition 0.5s
  padding 1rem
  cursor pointer
  background var(--primary)
  color var(--light)
  border 1px var(--light) solid
  margin-left -1em

input[type=file]
  font-size 1.2em

button.btn
  width 200px

button.del
  display inline
  border 1px solid red

</style>