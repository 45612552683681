<template lang='pug'>
ViewShirts
</template>

<script>
import ViewShirts from '../components/ViewShirts.vue'

export default {
  name: 'Home',
  components: {
    ViewShirts
  }
}
</script>
