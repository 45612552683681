<template lang='pug'>
i(class="fas fa-tshirt")

</template>

<script>
export default {

}
</script>

<style lang='stylus' scoped>
i
  font-size 9em
  animation spin 1.5s ease infinite
  transform-origin center center

@keyframes spin
  100% {transform: rotate(360deg)}
</style>