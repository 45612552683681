<template lang='pug'>
.returns
  h1 Returns Policy
  p Returns can be made within 30 days of receipt of goods by you.
  p If you wish to return your item to us, you can contact us either by <a href=mailto:info@noshit-shirts.com>email</a>, or by using the c<router-link to='/contact'>contact form.</router-link>
  p Please provide your order number in your email to assist the process.
  p We expect the returned items to be returned in an ‘as new’ condition.

  button(@click='close') OK
</template>

<script>
export default {
  methods: {
    close() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang='stylus' scoped>
button
  width 200px
  margin 0 auto

// #footer
//   overflow hidden
//   position fixed
//   left 0
//   bottom 0
//   border 1px red solid
</style>