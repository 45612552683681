<template lang='pug'>
.privacy
  //- h1 Our Privacy Policy
  //- p Noshit-shirts care about your privacy.
  //- p The only thing we keep is a record of sales and contact messages.
  //- p We do not share this information with anybody.
  //- p We do not keep your credit/debit card details - this is all handled by PayPal.  All we know is your name, email address and purchases.

  h1 Privacy Notice
  p We observe the protection of your privacy and confidentiality.
  p The personal data of visitors to our website will not be used for any purpose unintended by them, or accidentally fall into the hands of any third parties.
  p Our policy and actions comply with UK law, including that of the General Data Protection Regulation (GDPR). We advise you to learn about your rights and our obligations to you in the processing and control of your personal data by reading the information provided at www.knowyourprivacyrights.org.
  p As a customer your information will be used to verify your identity, enact a sale and despatch for delivery.
  p Following agreement to our terms and conditions and the purchase of our goods, a contract is formed between you and us. We process your information on the basis there is a contract between us, or that you have requested we use the information before we enter into a legal contract. The processing of your information will end when the contract between us ends or is terminated by either party under the terms of the contract.
  p The law requires us to determine under which defined bases we process different categories of your personal information, and to notify you of the basis for each category. If a basis on which we process your personal information is no longer relevant then we shall immediately stop processing your data. If the basis changes then if required by law we shall notify you of the change and of any new basis under which we have determined that we can continue to process your information.
  p In the event of statutory obligation we may be required to submit information to legal authorities either by request, search warrant or court order. This may include the personal data you have provided to us.
  p When you send us a message we collect the data you have given to us in order to reply with the information you need.
  p We do not use any cookies in our website.
  p We will retain your data only to provide you with the services you have requested; to comply with other law, including for the period demanded by our tax authorities; to support a claim or defence in court.
  p Our privacy policy may be reviewed as and when necessary.
  p If you have a complaint or an enquiry you can contact us via <a href=mailto:info@noshit-shirts.com>email</a>

  button(@click='close') OK
</template>

<script>
export default {
  methods: {
    close() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang='stylus' scoped>
button
  width 200px
  margin 0 auto
</style>