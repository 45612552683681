<template lang='pug'>
.edit
  h1 editing

  select(v-model='whichDesign' ref='editDropdown' @change='getMyShirt')
    option(value='select existing design to edit' disabled) select shirt to edit
    option(v-for='shirt in shirts' :value='shirt.name') {{ shirt.id }}

  .card(v-if='shirtIsSelected')
    h1 {{ myShirt.id }}

    .pic
      p.piccy
        img(v-if='myShirt.designBlob' :src='myShirt.designBlob')
        span(v-else) ** no image uploaded yet **
      div
        p select design image, should be a jpg, keep filesize down
        form(@submit.prevent="uploadBlob('design')")
          input(type='file' accept='.png, .jpg' @change="blobbify('myShirt.designBlob', $event)")
          p.upload(v-if='newDesignBlob') now upload da fucker
            button(type='submit') upload design

    .pic
      p.piccy
        img(v-if='myShirt.shirtBlob' :src='myShirt.shirtBlob')
        span(v-else) ** no image uploaded yet **
      div
        p select t-shirt thumb - transparent png, if you please
        form(@submit.prevent="uploadBlob('shirt')")
          input(type='file' id='thumb' accept='.png' @change="blobbify('myShirt.shirtBlob', $event)")
          p.upload(v-if='newShirtBlob') now upload da fucka
            button(type='submit') upload thumb

    h3
      span price:
      input(type='number' v-model='changedPrice')
      button.btn(@click='updatePrice') change price

    h3 sizes/types:
    ul
      li(v-for='(type, typeIdx) in myShirt.types')
        h4 size {{ typeIdx + 1 }}
          button.del(@click='deleteType(myShirt, typeIdx)') delete size
        h3
          span colour:
          input(type='text' v-model='myShirt.types[typeIdx].colour')
          button.btn(@click='updateColour(typeIdx)') change colour
        h3
          span size:
          input(type='text' v-model='myShirt.types[typeIdx].size')
          button.btn(@click='updateSize(typeIdx)') change size
        h3
          span stock:
          input(type='text' v-model='myShirt.types[typeIdx].stock')
          button.btn(@click='updateStock(typeIdx)') change stock
        hr

      h2 add a new type (ie size or colour)
      h4 type {{ newTypes.length + 1 }}
        h3
          span colour:
          input(type='text' v-model='newTypeColour')
        h3
          span size:
          input(type='text' v-model='newTypeSize')
        h3
          span stock:
          input(type='number' v-model='newTypeStock')

      button.btn(@click='addNewType') add new type

</template>

<script>
import { db } from '../../funky/firebaseInit'

export default {
  data() {
    return {
      // for editing existing design
      // but myShirt is existing shirt including types
      whichDesign: 'select existing design to edit',
      shirtIsSelected: false,
      myShirt: null,
      myShirtIndex: null,
      changedPrice: '',
      changedShirtBlob: null,
      changedDesignBlob: null,
      newDesignBlob: false,
      newShirtBlob: false,

      // for editing types
      // initially same as myShirt, but also new ones if necess
      newTypes: [],
      newColours: [],
      newSizes: [],
      newStocks: [],

      // for adding brand new type
      newTypeColour: '',
      newTypeSize: '',
      newTypeStock:'',

      error: false,
      errorDetails: ''
    }
  },

  methods: {
    getMyShirt() {
      // todo - way too verbose!
      this.shirtIsSelected = true
      this.myShirtIndex = this.$refs.editDropdown.selectedIndex - 1
      this.myShirt = this.shirts[this.myShirtIndex]
      this.reset()
    },

    reset() {
      this.changedName = this.myShirt.id
      this.changedPrice = this.myShirt.price
      this.designBlob = this.myShirt.designBlob
      this.newTypes = this.myShirt.types

      this.newTypes.forEach((type, idx) => {
        this.newColours[idx] = this.newTypes[idx].colour
        this.newSizes[idx] = this.newTypes[idx].size
        this.newStocks[idx] = this.newTypes[idx].stock
      })

      // for adding new type
      this.newTypeColour = ''
      this.newTypeSize = ''
      this.newTypeStock = ''

      this.error = false
      this.errorDetails = ''
    },

    blobbify(whichBlob, event) {
      const inputFile = event.target.files[0]
      const fileReader = new FileReader()

      fileReader.readAsDataURL(inputFile)
      fileReader.addEventListener('load', () => {
        const blob = fileReader.result
        if (whichBlob == 'myShirt.designBlob') {
          this.myShirt.designBlob = blob
          this.newDesignBlob = true
        } else {
          this.myShirt.shirtBlob = blob
          this.newShirtBlob = true
        }
      })
    },

    uploadBlob(type) {
      if (type=='design') {
        db.collection('shirts').doc(this.myShirt.id)
          .update({designBlob: this.myShirt.designBlob})
          this.newDesignBlob = false
      } else {
        db.collection('shirts').doc(this.myShirt.id)
          .update({shirtBlob: this.myShirt.shirtBlob})
          this.newShirtBlob = false
      }
    },

    updatePrice() {
      const newPrice = parseFloat(this.changedPrice)
      if (newPrice < 0 || isNaN(newPrice)) {
        let message = "can't have negative prices, dummy"
        message += "\n\nyou think we're a fuckin' charity or something?"
        alert(message)
        return
      }
      let message = "changing '" + this.myShirt.price + "' to '" + newPrice + "'"
      message += "\n\nARE YOU SURE?"

      if (confirm(message)) {
        let payload = {id: this.myShirtIndex, price: newPrice}
        this.$store.dispatch('changeShirtPrice', payload)

        db.collection('shirts').doc(this.myShirt.id)
          .update({price: newPrice})

        alert('price changed OK')
        return
      }
      alert('made a booboo')
      this.reset()
    },


    async updateColour(typeIdx) {
      const newColour = this.newColours[typeIdx]
      let message = "changing '" + this.myShirt.types[typeIdx].colour + "' to '" + newColour + "'"
      message += "\n\nARE YOU SURE?"

      if (confirm(message)) {
        let payload = {shirtIdx: this.myShirtIndex, typeIdx: typeIdx, colour: newColour}
        this.$store.dispatch('changeTypeColour', payload)

        this.newTypes[typeIdx].colour = newColour

        try {
          await db.collection('shirts').doc(this.myShirt.id)
          .update({types: this.newTypes})
        } catch (err) {
          // alert(err + 'something went wrong - let angelo know!')
          this.error = true
          this.errorDetails = err
        }

        if (this.error) {
          alert('😱 something went wrong - let angelo knpw about ' + this.errorDetails + ' 😱')
        } else {
          alert('👍 colour updated to ' + newColour + ' OK 👍')
          return
        }
      }
      this.reset()
    },

    updateSize(idx) {
      let message = "changing '" + this.myShirt.types[idx].size + "' to '" + this.newSizes[idx] + "'"
      message += "\n\nARE YOU SURE? - can't be undone, and would fuck things real bad if you're wrong!"

      if (confirm(message)) {
        let payload = {shirtIdx: this.myShirtIndex, typeIdx: idx, size: this.newSizes[idx]}
        this.$store.dispatch('changeTypeSize', payload)

        this.newTypes[idx].size = this.newSizes[idx]
        db.collection('shirts').doc(this.myShirt.id)
          .update({types: this.newTypes})

        return
      }
      this.reset()
    },

    updateStock(idx) {
      var newStock = parseFloat(this.newStocks[idx])
      if (newStock < 0) {
        let message = "negative stock? \n\nyou 'havin a giraffe?"
        alert(message)
        return
      }

      let message = "changing '" + this.myShirt.types[idx].stock + "' to '" + newStock + "'"
      message += "\n\nARE YOU SURE?"

      if (confirm(message)) {
        // vuex stuff
        let payload = {shirtIdx: this.myShirtIndex, typeIdx: idx, stock: newStock}
        this.$store.dispatch('changeTypeStock', payload)

        // firestore
        this.newTypes[idx].stock = newStock
        db.collection('shirts').doc(this.myShirt.id)
          .update({types: this.newTypes})
        return
      }
      this.reset()
    },

    addNewType() {
      const newType = {
        colour: this.newTypeColour,
        size: this.newTypeSize,
        stock: parseInt(this.newTypeStock)
      }

      // qq check entries, and confirm message

      const payload = {shirtIdx: this.myShirtIndex, newType: newType}
      this.$store.dispatch('addNewType', payload)

      db.collection('shirts').doc(this.myShirt.id)
        .update({types: this.newTypes})

      this.reset()
    },

    deleteType(myShirt, typeIdx) {
      // splicing
      const payload = {shirtIdx: myShirt.displayOrder, typeIdx: typeIdx}
      this.$store.dispatch('deleteType', payload)

      db.collection('shirts').doc(this.myShirt.id)
        .update({types: this.newTypes})

      this.reset()
    },

  },

   computed: {
    shirts() {
      return this.$store.state.shirts
    },

  },

}
</script>

<style lang='stylus' scoped>
.pic
  display flex
  align-items center

p.piccy
  min-width 400px

input
  display inline-block
  background var(--primary)
  padding-left 1em
  color var(--light)


.pic img
  max-width 300px
  &:hover
    cursor pointer
    transform skew(5deg)


input[type=file]::file-selector-button
  transition 0.5s
  padding 1rem
  cursor pointer
  background var(--primary)
  color var(--light)
  border 1px var(--light) solid
  margin-left -1em

input[type=file]
  font-size 1.2em

button.btn
  width 200px

.upload
  border 1px solid blue
  padding 0.5em
  background red
  color black
  animation blink-animation 1s steps(5, start) infinite

  @keyframes blink-animation {
        to {
          visibility: hidden;
        }
      }
</style>