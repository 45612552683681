<template lang='pug'>
.carty(@click='showCarty(false)')
  h1 your shopping cart
  .daCart(@click.stop='showCarty(true)')
    Cart
  button.back(@click='back') back to shirts

  Footer
</template>

<script>
import { eventBus } from '../main'
import Cart from './Cart.vue'
import Footer from './footer/Footer.vue'

export default {
  components: { Cart, Footer },

  created() {
    eventBus.$on('purchaseMade', (bool) => {
      this.purchaseMade = true
    })
  },

  data() {
    return {
      purchaseMade: false
    }
  },

  methods: {
    showCarty(bool) {
      eventBus.$emit('showCartModal', bool)

    },
    back() {
      console.log('back')
      if (this.purchaseMade) {
        this.$store.dispatch('clearCart')
        // qq route push viewshirts
        // this.$router.push('/')
        this.showCarty(false)
      } else {
        // this.$router.push('/viewshirts')
        // location.reload()
        eventBus.$emit('cartClosed')
      }
    }
  }
}
</script>

<style lang='stylus' scoped>
.carty
  top 0
  left 0
  position fixed
  background rgba(20, 27, 35, 0.99)
  width 100%
  height 100%
  cursor pointer
  display flex
  flex-direction column
  text-align center
  overflow-y auto
  scrollbar-width thin
  scrollbar-color var(--thumbBG) var(--scrollbarBG)
  padding-bottom 5em
  // border 1px solid green



// maybe remove body's scrollbar?
// then reinstate when cart modal closed
// FFS!

.carty::-webkit-scrollbar
  width 0.5em

.carty::-webkit-scrollbar-track
  // background var(--scrollbarBG)

// body::-webkit-scrollbar-thumb
//   background var(--thumbBG)
//   // border 3px solid var(--scrollbarBG)


.daCart
  width fit-content
  margin 0 auto

button.back
  width 400px
  margin 0 auto

#footer
  overflow hidden
  position fixed
  left 0
  bottom 0
  z-index 99
</style>