<template lang='pug'>
.backdrop(@click='closeModal')
  .modal
    img(:src='modalPic')
    br
    .message(v-if='shirtIndex >- 1')
      p '{{ shirt.id }}' added to your cart
      p size {{ shirt.types[typeIndex].size }}
    button(@click='closeModal') OK

</template>

<script>
import store from '../funky/store'


export default {
  props: ['modalPic', 'shirtIndex', 'typeIndex'],
  methods: {
    closeModal() {
      this.$emit('close')
    }
  },
  computed: {
    shirts() {
      return this.$store.state.shirts
    },
    shirt(shirtIndex) {
      return this.shirts[this.shirtIndex]
    }
  }
}
</script>

<style lang='stylus' scoped>
.backdrop
  top 0
  left 0
  position fixed
  background rgba(20, 27, 35, 0.99)
  width 100%
  height 100%
  cursor pointer
  display flex
  flex-direction column
  justify-content center

.modal
  text-align center

img
  opacity 1
  margin-bottom 1em

.message p
  font-size 1.5em
</style>