<template lang='pug'>
.addNewDesign
  h1 add new design
  .card
    h1 new shirt details
    h3
      span new design name:
      input(type='text' id='newName' v-model='newName' @change='checkNameConflict($event)')

    .pic
      p.piccy
        img(v-if='newDesignBlob' :src='newDesignBlob')
        span(v-else) ** no design image chosen yet **
      div
        p select design image, should be a jpg, keep filesize down
        input#dez(type='file' accept='.png, .jpg' @change="blobbify('newDesignBlob', $event)")
    br
    br

    .pic
      p.piccy
        img(v-if='newShirtBlob' :src='newShirtBlob')
        span(v-else) ** no tshirt thumbnail chosen yet **
      div
        p select t-shirt thumb - transparent png, if you please
        input#fum(type='file' accept='.png' @change="blobbify('newShirtBlob', $event)")

    h3
      span price:
      input(type='number' id='newPrice' v-model='newPrice' @change='checkEventValueNegative($event)')

    h3 size/type:
      h4 size 1  - for multiple sizes, make first size, then edit exisiting shirt later, once it exists - if that makes sense!
        h3
          span colour:
          input(type='text' v-model='newColour')
        h3
          span size:
          input(type='text' v-model='newSize')
        h3
          span stock:
          input(type='number' id='newStock' v-model='newStock' @change='checkEventValueNegative($event)')

    button(@click='addNewShirt') add new shirt design
    br
    br
    hr

</template>

<script>
import { db } from '../../funky/firebaseInit'
import store from '../../funky/store'


export default {
  name: 'addnewdesign',

  data() {
    return {
      // // for adding new type
      //   newTypes: [],
      //   newColours: [],
      //   newSizes: [],
      //   newStocks: [],
      //   newTypeColour: '',
      //   newTypeSize: '',
      //   newTypeStock:'',

        // for adding new shirt
        newShirt: {},
        newName: null,
        // newDesign: null,
        newDesignBlob: null,
        // newShirt: null,
        newShirtBlob: null,
        newPrice: null,
        newColour: null,
        newStock: null,
        newSize: null,
        // newType: {},

        // adding: true
        dez: null
    }
  },

  methods: {
    addNewShirt() {
      if (!this.newName) {
        alert('you need to enter a new name, dummy!')
        // focus??
        return
      }
      if (this.checkNameConflict(this.newName)) {
        alert('bad name')
        return
      }
      if (!this.newDesignBlob) {
        alert('this shirt has no design - I think it might need one')
        return
      }
      if (!this.newShirtBlob) {
        alert('this shirt has no thumbnail - I think it needs one')
        return
      }
      if (!this.newPrice) {
        alert('no price set - you cannot be serious!')
        return
      }
      if (this.checkNegativeValue(this.newPrice, 'newPrice')) {
        alert('bad price value')
        return
      }
      if (!this.newColour) {
        alert('no colour set - is this shirt see-through?')
        return
      }
      if (!this.newSize) {
        alert('what size is it? as actress said to bishop')
        return
      }
      // if (!this.newStock) {
      //   alert('no stock?')
      //   return
      // }
      if (this.checkNegativeValue(this.newStock, 'newStock')) {
        alert('bad stock')
        return
      }

      this.newShirt = {
        name: this.newName,
        designBlob: this.newDesignBlob,
        shirtBlob: this.newShirtBlob,
        price: parseFloat(this.newPrice),
        displayOrder: this.$parent.shirts.length + 1,
        types: [{
          colour: this.newColour,
          size: this.newSize,
          stock: parseInt(this.newStock)
        }],
        sales: 0
      }

      db.collection('shirts').doc(this.newName).set(this.newShirt)
      store.dispatch('addNewShirt', this.newShirt)

      // reset 'new' variables
      this.newName = null
      this.newDesignBlob = null
      this.newShirtBlob = null
      this.newPrice = null
      this.newColour = null
      this.newSize = null
      this.newStock = null
      this.adding = false
      document.getElementById('dez').value = ''
      document.getElementById('fum').value = ''


      alert('new shirt added successfully - give yourself a pat on the back!')
    },

    // used when adding/changing new name
    checkEventNameConflict(event) {
      if (this.checkNamesConflict(this.newName)) {
        document.getElementById(event.target.id).focus()
      }
    },

    // used on final verifications
    checkNameConflict(newName) {
      this.$parent.shirts.forEach(shirt => {
        if (newName == shirt.name) {
          let message = "You already have a shirt called " + newName
          message += "\n\nEither choose a new name for this shirt, or delete the other one first"
          alert(message)
          document.getElementById('newName').focus()
          return true
        }
      })
      // alert('name OK')
      return false
    },


    checkEventValueNegative(event) {
      // console.log(event.target.value)
      if (this.checkNegativeValue(event.target.value, event.target.id)) {
        document.getElementById(event.target.id).focus()
      }
    },

    checkNegativeValue(value, id) {
      if (value < 0) {
        let message = "can't have a negative value here, try again, silly boy"
        alert(message)
        document.getElementById(id).focus()
        return true
      }
      // alert(id + ' value ok')
      return false
    },

     blobbify(whichBlob, event) {
      const inputFile = event.target.files[0]
      const fileReader = new FileReader()

      // console.log(inputFile)
      this.designFileName = inputFile.name

      fileReader.readAsDataURL(inputFile)
      fileReader.addEventListener('load', () => {
        const blob = fileReader.result
        if (whichBlob == 'newDesignBlob') {
          this.newDesignBlob = blob
          // console.log(inputFile.name)
          // console.log(this.dez)
          // document.getElementById('dez').style.color='transparent'
          console.log(document.getElementById('dez').value)
        } else {
          this.newShirtBlob = blob
        }
      })
    },

  } // end methods
}
</script>

<style lang='stylus' scoped>
.pic
  display flex
  align-items center

p.piccy
  min-width 400px

input
  display inline-block
  background var(--primary)
  padding-left 1em
  color var(--light)

.pic img
  max-width 300px
  &:hover
    cursor pointer
    transform skew(5deg)

input[type=file]::file-selector-button
  transition 0.5s
  padding 1rem
  cursor pointer
  background var(--primary)
  color var(--light)
  border 1px var(--light) solid
  margin-left -1em

input[type=file]
  font-size 1.2em

button.btn
  width 200px
</style>