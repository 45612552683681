<template lang='pug'>
.viewshirts
  .main
    .spiel
      h1 Desperate designs for modern times
      p 100% cotton, all prices include postage and packing to the UK
    .spinner(v-if='!shirts.length')
      Spinner
    .store(v-else)
      .card(v-for='shirt in shirts')
        .cardTitle {{ shirt.id }}
        .piccys
          img.design(:src='shirt.designBlob' @click='showModal(shirt.designBlob)')
          .gap
          img(:src='shirt.shirtBlob')

        form(:id='`f`+shirt.displayOrder')
          select(:v-model='`sel` + shirt.displayOrder' @change='showAddToCartButton(shirt, shirt.displayOrder)' :id='`a`+shirt.displayOrder')
            option(value="" disabled selected) click here to select size
            option(v-for='type in shirt.types' v-if='type.stock' v-bind:value='type.size') {{ type.size }}
            option(v-else :value='none') {{ type.size }} restocking - click to preorder

        .price
          button.cart(:id="`b` + shirt.displayOrder" type='submit' @click='addToCart(shirt)')
            img(src='../assets/add-to-cart.png')
          span £{{ shirt.price }}
        span &nbsp;

  Footer

  ShirtModal(v-if='modalShown' :modalPic='modalPic'
      :shirtIndex='shirtIndex' :typeIndex='typeIndex' @close='closeModal')

  CartModal(v-if='cartModalShown')


</template>


<script>
import ShirtModal from './ShirtModal.vue'
import CartModal from './CartModal.vue'
import Spinner from './Spinner.vue'
import Footer from './footer/Footer.vue'
import { eventBus } from '../main'


export default {
  name: 'viewshirts',
  components: { ShirtModal, Spinner, CartModal, Footer },

  created() {
    eventBus.$on('showCartModal', (bool) => {
      this.cartModalShown = bool
    })
  },

  data() {
    return {
      modalPic: null,
      modalShown: false,
      shirtIndex: null,
      typeIndex: null,
      none: 'nope',
      selectedSizes: [],
      selectedSize: '',
      selectedType: null,
      cartModalShown: false,
    }
  },

  computed: {
    shirts() {
      return this.$store.state.shirts
    },
    purchases() {
      return this.$store.state.purchases
    }
  },

  methods: {
    showModal(blob, shirtIndex, typeIndex) {
      this.modalPic = blob
      this.shirtIndex = shirtIndex
      this.typeIndex = typeIndex
      this.modalShown = true
    },
    closeModal() {
      this.modalShown = false
      this.resetSelectsAndButtons()
    },

    showCartModal() {
      this.cartModalShown = true
    },

    closeCartModal() {
      this.cartModalShown = false
    },

    showAddToCartButton(shirt, displayOrder) {
      const chosenShirt = document.getElementById('a'+displayOrder)
      const chosenSize = chosenShirt.value
      if (chosenSize == 'nope') {
        this.$router.push('/contact')
        return
      }
      const chosenType = chosenShirt.selectedIndex - 1
      this.selectedSize = chosenSize
      this.selectedType = chosenType
      const btn = document.getElementById('b'+displayOrder)
      btn.style.visibility ='visible'
    },

    addToCart(shirt) {
      if (shirt.types[this.selectedType].stock > 0) {
        const purchaseIndices = {
          shirtIndex: shirt.displayOrder,
          typeIndex: this.selectedType
        }
        this.$store.dispatch('addToCart', purchaseIndices)
        this.modalShown = true
        this.showModal(shirt.shirtBlob, shirt.displayOrder, this.selectedType)
      } else {
        console.log('not enough stock')
      }
    },

    resetSelectsAndButtons() {
      for (let i=0;i<this.shirts.length;i++) {
        let selectId = 'f' + i
        document.getElementById(selectId).reset()
        let buttonId = 'b' + i
        document.getElementById(buttonId).style.visibility = 'hidden'
      }
    },

  } // end methods

} // end export
</script>

<style lang='stylus' scoped>
.main
  margin-bottom 0
  padding-bottom 0

.spinner
  max-width 600px
  max-height 600px
  margin 0 auto
  text-align center

.store
  display flex
  flex-wrap wrap
  justify-content center

.piccys
  display flex
  justify-content center

img
  max-width 200px
  border 3px solid rgba(0,0,0,0)
  object-fit scale-down

img.design:hover
    cursor pointer
    border 3px solid var(--dark)

.gap
  width 1em

.price span
  position absolute
  right 0
  margin-right 0.3em
  font-size 2em

select
  margin-top 0.5em
  width 100%

button.cart
  margin 0
  padding 0
  border none
  background transparent
  margin-top 0.5em
  visibility hidden

#footer
  overflow hidden
  position fixed
  left 0
  bottom 0


@media (max-width: 44em)
  .piccys
    flex-direction column
    align-items center

  .spinner
    max-width 200px


</style>