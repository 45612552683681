<template lang='pug'>
.terms
  h1 Terms and Conditions
  p All purchases made on the Noshi T-shirts site are subject to the following terms and conditions, none of which affects your statutory rights:
  ul
    li We aim to faithfully pass on all descriptions and specifications provided us by the manufacturers. However, errors may occur and if for any reason you are not satisfied, we recommend you <router-link to='/contact'>contact us</router-link> with any questions or complaints.
    li The designs and colours shown are representative only.
    li Actual sizes may vary depending on the suppliers used.
    li All items are subject to availability.

  h3 Acceptance of Orders
  p By clicking on the ‘PayPal’ button you are agreeing to these terms. This, however, represents an offer of contract from you only and is not binding until accepted by us by dispatching the goods to you with a dispatch note issued by us to you.
  p Receipt of an order via our website does not constitute acceptance of an order. We reserve the right to refuse your offer for any reason. We reserve the right to amend any omissions or errors on any page or in any documents without liability on our part.
  p You, as the customer, are responsible for the accuracy of any order made and for the giving of such necessary information relating to the sale terms and delivery.
  h3 Purchases Outside the UK
  p Please enquire via the <router-link to='/contact'>contact form.</router-link>
  h3 General Sales
  p All purchases must be paid in full before delivery.
  p Noshi T-shirts will continue to own, and reserve the right to repossess any goods sold from its web site until payment has been received and cleared in full.
  p Payments are accepted on most credit or debit cards. We authorise you to make any enquiries you deem necessary in connection with this sale. This information will be used for fraud prevention or debt recovery. In the unlikely event, we cannot be held responsible for any damage caused or loss of data as a result of using any of our products.
  p All orders are shipped from within mainland UK.
  p All written content, photographs and artwork are copyright ©Noshi T-shirts.

  button(@click='close') OK
</template>

<script>
export default {
  methods: {
    close() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang='stylus' scoped>
li
  color #ddd
  list-style-type disc

button
  width 200px
  margin 0 auto
</style>