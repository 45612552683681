import Vue from 'vue'
import App from './App.vue'
import router from './funky/routes'
import store from './funky/store'

export const eventBus = new Vue()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// todo move firestore into its own function/component along with a loading spinner

// get shirts from firebase
// import db from './funky/firebaseInit'

// var index = 0
// db.collection('shirts').get()
//   .then(querySnapshot => {
//     querySnapshot.forEach(doc => {
//       const shirt = {
//         'index': index,
//         'id': doc.id,
//         // 'name': doc.data().name,
//         'price': doc.data().price,
//         'shirtBlob': doc.data().shirtBlob,
//         'designBlob': doc.data().designBlob,
//         'types': doc.data().types,
//       }
//       index++
//       // stick each shirt in vuex store
//       // should dispatch action, but dunno how to map action here
//       store.commit('addShirt', shirt)
//     })
//   })
