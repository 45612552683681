<template lang='pug'>
.checkout
  .empty(v-show='!cart.length')
    p your cart is currently empty...
    //- button(@click='addToSalesDb' v-if='isLocal') angelo

  .cart(v-show='cart.length')
    .card(v-for='(purch, pIdx) in cart')
      .cardTitle {{ shirts[purch.shirtIndex].id }}
      .piccys
        img(:src='shirts[purch.shirtIndex].designBlob')
        .gap
        img(:src='shirts[purch.shirtIndex].shirtBlob')

      p size: {{ shirts[purch.shirtIndex].types[purch.typeIndex].size }}, colour {{ shirts[purch.shirtIndex].types[purch.typeIndex].colour }}
      p.quantity
        p quantity
          button.q(@click='decreaseQ(purch, pIdx)' :disabled="buttonIsDisabled('decrease', purch)") -
          span.q {{ purch.quantity }}
          button.q(@click='increaseQ(purch, pIdx)' :disabled="buttonIsDisabled('increase', purch)") +
      p price £{{ purchasePrice(purch) }}  &nbsp;(@£{{ typePrice(purch) }} each)
        br
        span includes postage and packing to the UK

      //- p (stock remaining: {{ shirts[purch.shirtIndex].types[purch.typeIndex].stock }})

      span.ordered(v-if='paidFor') ordered
      br

    .nope(v-show='!paidFor')
      .total
        h2 total price £{{ totalPrice }}
      .pp2(ref='paypal')
    .yep(v-if='paidFor')
      h2 Thanks for your order
      p You will receive an email confirmation soon.

</template>

<script>
import { db, fv } from '../funky/firebaseInit'
import { eventBus } from '../main'

export default {
  name: 'cart',
  data() {
    return {
      loaded: false,
      paidFor: false,
      shirtsOrdered: [],
      newSale: {},
      description: '',
    }
  },

  mounted: function() {
    const script = document.createElement('script')

    // sandbox
    // script.src="https://www.paypal.com/sdk/js?client-id=AVg_2Qb78khvBFydZyeksh112bAtpO16q1XjU3NTMWhYlTPUe0CctcoKT3HSim1ZCDcbzpT3UYyR2hoM&currency=GBP"

    // rufus the real
    script.src = "https://www.paypal.com/sdk/js?client-id=AQccRaGwpJMLvKM5qkf_HU9ofZrUy2OwVQDfVKtIIhqCrBuAkyNfWux1kDh6KczVwJRPWHtIylAZUgyX&currency=GBP"

    script.addEventListener('load', this.setLoaded)
    document.body.appendChild(script)
  },

  methods: {
    setLoaded: function() {
      this.loaded = true
      window.paypal
        .Buttons({
          // fired after paypal popup pay
          createOrder: (data, actions) => {
            this.buildSalesData()

            return actions.order.create({
              purchase_units: [{
                  description: this.description,
                  amount: {
                    currency_code: 'GBP',
                    value: this.totalPrice
                  }
                }]
            })
          },

          // fired after approval - ie funds available, purchase made
          onApprove: async (data, actions) => {
            const order = await actions.order.capture()
            this.data

            this.paidFor = true
            this.newSale = {
              payPalOrderId: order.id,
              time: order.create_time,
              punter: order.payer.name.given_name + ' ' + order.payer.name.surname,
              email: order.payer.email_address,
              postal: order.purchase_units[0].shipping.address,
              order:  this.shirtsOrdered
            }

            this.updateShirtSales()
            this.updateShirtStock()
            this.updateSales(order)

            this.sendNoshiEmail(order)
            this.sendPunterEmail(order)

            eventBus.$emit('purchaseMade', true)

          },
          onCancel: function (data) {
            // todo cancelled
          },
          onError: err => {
            console.log('pp', err)
            // window.location.href = "/your-error-page-here";
          },
        })
        .render(this.$refs.paypal)
    },

    buildSalesData() {
      this.cart.forEach(purchase => {
        let shirt = this.shirts[purchase.shirtIndex]
        let type = shirt.types[purchase.typeIndex]
        this.description += purchase.quantity + ' x ' + type.size + ' ' + shirt.id
          + '@£' + shirt.price + ' each = £' + (purchase.quantity * shirt.price) + '<br>'
        const sale = {
          shirt: shirt.id,
          size: type.size,
          quantity: purchase.quantity
        }
        this.shirtsOrdered.push(sale)
      })
      this.description += '<h3>Total price: £' + this.totalPrice + '</h3>'
    },

    // shirts have a sales field, shows how many have been sold
    updateShirtSales() {
      for (let i=0; i<this.cart.length; i++) {
        let item = this.cart[i]
        let shirt = this.shirts[item.shirtIndex]
        db.collection('shirts').doc(shirt.id)
          .update({sales: fv.increment(item.quantity)})
      }
    },

    // each shirt type has a stock number -> decrement when sold!
    updateShirtStock() {
      // update stock levels in fbase
      // read order details from cart
      // for each purchase, update fstore stock from vuex
      this.cart.forEach(purchase => {
        let shirt = this.shirts[purchase.shirtIndex]

        // need to rebuild types array, then update
        const newTypes = []
        shirt.types.forEach(oldType => {
          let newType = {
            colour: oldType.colour,
            size: oldType.size,
            stock: oldType.stock
          }
          newTypes.push(newType)
        })

        db.collection('shirts').doc(shirt.id)
          .update({types: newTypes})
      })
    },

    // update the sales collection with newSale doc
    updateSales(order) {
      // get and increment salesSoFar counter
      const counterRef = db.collection('sales').doc('counter')
      counterRef.get()
        .then(doc => {
          if (doc.exists) {
            const salesSoFar = doc.data().salesSoFar
            counterRef.update({salesSoFar: fv.increment(1)})

            // save sale details
            const string = (salesSoFar + 1).toString()
            db.collection('sales').doc(string).set(this.newSale)
        } else {
          console.log("can't find counter doc!")
        }
      })
    },

    sendPunterEmail() {
      let punterDescription = '<h2>order confirmation</h2>' + '<p>Thank you for your order</p>'
      punterDescription += this.description
      punterDescription += '<p>We will dispatch your order as soon as we can.</p>'
        + "<p><a href='mailto:info@noshit-shirts.com'>Do contact us if you have any problems.</a></p>"

      // todo build html with order details, somehow with blobs etc
      const email = {
        to: order.email_address,
        // to: 'mickey.megabyte@gmail.com',
        message: {
          subject: 'Thanks for your noshit-shirt order',
          html: punterDescription
        }
      }
      db.collection('send-mails').add(email)
    },

    sendNoshiEmail() {
      // todo  make prettier - emoticons etc!!!
      // what does rufus need? just the paypal id??? and
      const stringy = JSON.stringify(this.newSale, null, '\t')
      let pretty = "<h1>Noshi order</h1>"
      pretty += "<pre>" + stringy + "</pre>"

      const email = {
        to: 'info@noshit-shirts.com',
        // to: 'mickey.megabyte@gmail.com',
        message: {
          subject: 'Noshit-shirts order',
          html: pretty
        }
      }
      db.collection('send-mails').add(email)
    },

    increaseQ(p, pIdx) {
      // check if enough stock
      if (this.shirts[p.shirtIndex].types[p.typeIndex].stock > 0) {
        const indices = {shirtIndex: p.shirtIndex, typeIndex: p.typeIndex, purchaseIndex: pIdx}
        // commit shirt stock mutation
        this.$store.commit('addOneShirtOfSameType', indices)
        // commit cart quantity mutation
        this.$store.commit('removeShirtFromStock', indices)
      }
    },

    decreaseQ(p, pIdx) {
      if (p.quantity > 0) {
        const indices = {shirtIndex: p.shirtIndex, typeIndex: p.typeIndex, purchaseIndex: pIdx}
        // commit shirt stock mutation
        this.$store.commit('removeOneShirtOfSameType', indices)
        // commit cart quantity mutation
        this.$store.commit('addShirtToStock', indices)
      }
      if (p.quantity == 0) {
        this.$store.commit('removePurchase', pIdx)
      }
    },

    typePrice(p) {
      return this.shirts[p.shirtIndex].price
    },

    purchasePrice(p) {
      return this.shirts[p.shirtIndex].price * p.quantity
    },

    noStock(p) {
      if (this.shirts[p.shirtIndex].types[p.typeIndex].stock > 0) {
        return false
      }
      return true
    },

    buttonIsDisabled(btnType, p) {
      if (btnType == 'decrease' && p.quantity == 0) {
        return true
      }
      if (btnType == 'increase' && this.noStock(p)) {
        return true
      }
      if (this.paidFor) {
        return true
      }
      return false
    },

  }, // end methods

  computed: {
    cart() {
      return this.$store.state.cart
    },
    shirts() {
      return this.$store.state.shirts
    },

    totalPrice() {
      let total = 0
      this.cart.forEach(p => {
        total += this.purchasePrice(p)
      })
      return total
    },

    showPayPal() {
      if (this.paidFor || this.totalPrice == 0) {
        return false
      }
      return true
    },

    isLocal() {
      if (window.location.host == 'localhost:8080') {
        return true
      }
      return false
    },
  },

}
</script>

<style lang='stylus' scoped>
.checkout
  display flex
  flex-wrap wrap
  justify-content center
  overflow-y auto
  padding-bottom 1em

span.q
  margin-left 1em

button.q
  width 1.5em
  padding 0.35em

button:disabled
  border 1px solid red
  background #444

.card
  max-width 500px

.piccys
  display flex
  justify-content center

img
  max-width 200px
  border 3px solid rgba(0,0,0,0)
  object-fit scale-down

.gap
  width 1em

.ordered
  font-size 5em
  display: inline-block;
  font-family old_stamper
  color black
  right 0.5em
  top 2em
  z-index 1
  position absolute
  transform: rotate(-45deg) skew(-10deg, 0)
  margin-bottom -6em

.nope
  display flex
  flex-direction column

.pp
  z-index -1
</style>