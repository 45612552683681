<template lang='pug'>
#app
  Navbar
  .container
    router-view
</template>

<script>
import Navbar from './components/Navbar'
import { db } from './funky/firebaseInit'
import store from './funky/store'

export default {
  name: 'app',
  components: {
    Navbar
  },
  beforeCreate() {
    try {
      let index = 0
      db.collection('shirts').orderBy('displayOrder').get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var types = []
            var ti = 0 // index of type
            const indexedTypes = doc.data().types

            // each type needs an index
            indexedTypes.forEach(indexedType => {
              const type = {
                'typeIndex': ti,
                'colour': indexedTypes[ti].colour,
                'stock': indexedTypes[ti].stock,
                'size': indexedTypes[ti].size,
              }
              types.push(type)
              ti++
            })

            // give new display order, and update fb b4 vuex
            // adding deleting shirts may lead to multiple shirts having same order
            if (doc.data().displayOrder != index) {
              db.collection('shirts').doc(doc.id).update({displayOrder: index})
            }

            const shirt = {
              'id': doc.id,
              'displayOrder': index,
              'price': doc.data().price,
              'shirtBlob': doc.data().shirtBlob,
              'designBlob': doc.data().designBlob,
              'types': types,
              'sales': doc.data().sales
            }

            // stick each shirt in vuex store
            store.dispatch('addNewShirt', shirt)
            index++
          })
        })
    } catch(err) {
      let error = err.message
      console.log(error)
      // qq todo should really email error to angelo!
    }
  }
}

</script>

<style lang="stylus">
:root
  --primary #202B38
  --dark #141B23
  --med #33455a
  --light #9eb1c8
  --modal rgba(20, 27, 35, 0.9)

@import './styles/form.styl'
@import './styles/typo.styl'
@import './styles/layout.styl'


</style>