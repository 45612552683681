<template lang='pug'>
.contact
  form(@submit.prevent='handleForm')
    h1 contact
    input(type='text' v-model='name' required placeholder='name')
    input(type='email' v-model='email' required placeholder='email address')
    textarea(rows='10' v-model='message' required)
    br
    button(type='submit') send message

  MyModal.mod(v-if='showMyModal' @close='showMyModal = false')
    h1(slot='header') 💬
    div(slot='body')
      p Thanks for getting in touch with noshi t-shirts, {{ name2 }}.
      p We will get back to you soon.

  CartModal(v-if='cartModalShown')

  Footer

</template>

<script>
import { db } from '../funky/firebaseInit'
import CartModal from './CartModal.vue'
import Footer from './footer/Footer.vue'
import MyModal from './MyModal.vue'

import { eventBus } from '../main'

export default {
  name: 'contact',
  components: { CartModal, Footer, MyModal },

  created() {
    // listen for event on da bus!
    eventBus.$on('showCartModal', (bool) => {
      this.cartModalShown = bool
    })
  },

  data() {
    return {
      name: '',
      name2: '',
      email: null,
      message: null,
      cartModalShown: false,
      showMyModal: false
    }
  },

  methods: {
    handleForm() {
      // for the modal, as this.name gets cleared
      this.name2 = this.name

      let daMessage = "<p style='font-size:4em; margin-bottom:0.125em'>“</p>" + this.message + "<p style='font-size:4em; margin-top:0.125em'>”</p>"
      daMessage += '<p>from <b>' + this.name + "</b>  <a href='mailto:" + this.email + "'>" + this.email + '</a></p>'
      daMessage += "<p>(Rufus, don't simply reply to this email, click on the email address above.)</p>"

      // send rufus email
      let noshiMessage = {
        // to: 'mickey.megabyte@gmail.com',
        to: 'info@noshit-shirts.com',
        message: {
          subject: 'A noshi punter, called ' + this.name + ', contacted you...',
          html: daMessage
        }
      }
      this.showMyModal = true

      db.collection('send-mails').add(noshiMessage)

      this.name = ''
      this.email = ''
      this.message = ''
      },
    },// end handleForm

    showCartModal() {
      this.cartModalShown = true
    },

    closeCartModal() {
      this.cartModalShown = false
    },

}
</script>

<style lang='stylus' scoped>
.contact
  text-align center
  padding 0
  display flex
  justify-content center

form
  max-width 1000px

input, textarea
  padding 0.85em
  margin-bottom 0.25em

button
  width 250px
  margin 0 auto

#footer
  overflow hidden
  position fixed
  left 0
  bottom 0


</style>